import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import SingleBlogDetails2 from '../components/single-blog/SingleBlogDetails2';
import BrandOne from '../components/brand/BrandOne';
import FooterOne from '../common/footer/FooterOne';

const BlogDetails = () => {
    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Sustentabilidade na Indústria : Compromisso Ambiental"
                currentPage="Artigo" 
                Img="blog-v1-img2.jpg"
            />
            <SingleBlogDetails2 />
            <BrandOne />
            <FooterOne />
        </>
    )
}

export default BlogDetails;