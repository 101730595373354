import React from 'react';
import {Link} from 'react-router-dom';

export default class ErrorContent extends React.Component {
    render(){
        return (
            <>
                <div className="error-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="error-page__wrapper text-center">
                                    <div className="error-page__content">
                                        <h2>404</h2>
                                        <h3>Opps! Pagina não encontrada</h3>
                                        <p>Desculpe, mas a pagina qu estas a procurar não existe.</p>
                                        <div className="btn-box">
                                            <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn" data-text="voltar ao inicio +">Voltar para pagina incial
                                                +</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}