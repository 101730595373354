import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import SingleGeneralContracting6 from '../components/single-service/SingleGeneralContracting6';
import BrandOne from '../components/brand/BrandOne';
import FooterOne from '../common/footer/FooterOne';

const GeneralContracting6 = () => {
    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Fornecimento de Equipamento"
                currentPage="serviço" 
                Img="IMG-20230809-WA0010.jpg"
            />
            <SingleGeneralContracting6 />
            <BrandOne />
            <FooterOne />
        </>
    )
}

export default GeneralContracting6;