import React from 'react';
import {Link} from 'react-router-dom';

export default class BlogThree extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="blog-one-sec">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Destaques</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title">Nossos destaques recentes</h2>
                        </div>
                        <div className="row">
                            {/* Start Blog One Single */}
                            <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.1s">
                                <div className="blog-one__single">
                                    <div className="blog-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/blog/blog-v1-img1.jpg"} alt="" />
                                        </div>
                                        <div className="blog-one__single-content">
                                            <div className="date-box text-center">
                                                <span>12</span>
                                                <h4>Maio</h4>
                                            </div>
                                            <div className="inner-content">
                                                <ul className="meta-info">
                                                    <li><span className="icon-account"></span><Link to={process.env.PUBLIC_URL + `/`}>José Rocha</Link></li>
                                                    <li><span className="icon-bubble-chat"></span><Link to={process.env.PUBLIC_URL + `/`}>
                                                        0 comentarios 
                                                    </Link>
                                                    </li>
                                                </ul>
                                                <h2><Link to={process.env.PUBLIC_URL + `/artigo1`}>Inovações : Acesso por Corda</Link></h2>
                                                <p>Explore as últimas tendências em técnicas de acesso por corda e como estão revolucionando inspeções industriais.</p>
                                                <div className="btn-box">
                                                    <Link to={process.env.PUBLIC_URL + `/artigo1`}>Ler mais <span
                                                            className="icon-right-arrow-1"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Blog One Single */}

                            {/* Start Blog One Single */}
                            <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.3s">
                                <div className="blog-one__single">
                                    <div className="blog-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/blog/blog-v1-img2.jpg"} alt="" />
                                        </div>
                                        <div className="blog-one__single-content">
                                            <div className="date-box text-center">
                                                <span>14</span>
                                                <h4>Junho</h4>
                                            </div>
                                            <div className="inner-content">
                                                <ul className="meta-info">
                                                    <li><span className="icon-account"></span><Link to={process.env.PUBLIC_URL + `/`}>José Rocha</Link></li>
                                                    <li><span className="icon-bubble-chat"></span><Link to={process.env.PUBLIC_URL + `/`}>0 Comentários</Link>
                                                    </li>
                                                </ul>
                                                <h2><Link to={process.env.PUBLIC_URL + `/artigo2`}>Sustentabilidade na Indústria :  Compromisso Ambiental</Link></h2>
                                                <p>Saiba mais sobre os esforços da SEPIMA para integrar práticas sustentáveis em cada serviço. </p>
                                                <div className="btn-box">
                                                    <Link to={process.env.PUBLIC_URL + `/artigo2`}>Ler mais <span className="icon-right-arrow-1"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Blog One Single */}

                            {/* Start Blog One Single */}
                            <div className="col-xl-4 col-lg-4 wow animated fadeInUp" data-wow-delay="0.5s">
                                <div className="blog-one__single">
                                    <div className="blog-one__single-img">
                                        <div className="inner">
                                            <img src={publicUrl+"assets/images/blog/blog-v1-img3.jpg"} alt="" />
                                        </div>
                                        <div className="blog-one__single-content">
                                            <div className="date-box text-center">
                                                <span>11</span>
                                                <h4>Julho</h4>
                                            </div>
                                            <div className="inner-content">
                                                <ul className="meta-info">
                                                    <li><span className="icon-account"></span><Link to={process.env.PUBLIC_URL + `/`}>José Rocha</Link></li>
                                                    <li><span className="icon-bubble-chat"></span><Link to={process.env.PUBLIC_URL + `/`}>0 Comentarios</Link>
                                                    </li>
                                                </ul>
                                                <h2><Link to={process.env.PUBLIC_URL + `/artigo3`}>Os Benefícios da Isolação Térmica</Link></h2>
                                                <p>Descubra como a isolação térmica pode otimizar a eficiência energética em instalações industriais. </p>
                                                <div className="btn-box">
                                                    <Link to={process.env.PUBLIC_URL + `/artigo3`}>Ler mais <span className="icon-right-arrow-1"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Blog One Single */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}