import React from 'react';
import {Link} from 'react-router-dom';

export default class ServiceOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="services-one-sec">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Nossos serviços</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title">Nossos serviços profissionais</h2>
                        </div>

                        <div className="row">
                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/services-v1-img1.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico1`}>Serviço de limpeza de Duto</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>A limpeza de dutos é uma parte essencial da manutenção para garantir o fluxo eficiente de produtos petrolíferos e prevenir obstruções.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico1`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/services-v1-img2.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico2`}>Isolação Térmica e Soldadura</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>A isolação térmica é crucial em operações petrolíferas para garantir a segurança e a eficiência, A soldagem é uma técnica comum usada na construção e manutenção de estruturas na indústria petrolífera.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico2`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/services-v1-img3.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico3`}>Meio de Acesso em Altura</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p> Além do acesso por corda, pode incluir outras técnicas e equipamentos para trabalhar em alturas, garantindo a segurança dos trabalhadores.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico3`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}