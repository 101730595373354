import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import ContactMap from "../components/contact/ContactMap";
import ContactForm from "../components/contact/ContactForm";
import BrandOne from "../components/brand/BrandOne";
import FooterOne from "../common/footer/FooterOne";
import AppointmentOne from '../components/appointment/AppointmentOne';

const ContactPage = () => {
    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Contacte-nos"
                currentPage="Contacto" 
                Img="cc5.jpg"
            />
            <ContactMap />
            <ContactForm />
            <BrandOne />
            {/* <AppointmentOne /> */}
            <FooterOne />

            
        </>
    )
}

export default ContactPage;