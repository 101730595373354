import React from 'react';

export default class BlogQuery extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <div className="sidebar__single sidebar__support">
                    <div className="sidebar__support-bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/sidebar-support-bg.jpg)'}}></div>
                    <h3 className="sidebar__suppot-title">Tem alguma questão?</h3>
                    <p className="sidebar__suppot-text">Entre em contacto connosco</p>
                    <div className="sidebar__support-btn-box">
                        <button className="thm-btn" data-text="Envie uma mensagem +" type="submit" data-loading-text="Please wait...">Enviar +</button>
                    </div>
                </div>
            </>
        )
    }
}