import React from 'react';

export default class ContactMap extends React.Component {
    render(){
        return (
            <>
                <div className="contact-page-google-map">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <iframe
                                    title="Address"
                                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3942.5130937633253!2d13.230086575016593!3d-8.831727691221905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwNDknNTQuMiJTIDEzwrAxMyc1Ny42IkU!5e0!3m2!1spt-PT!2sao!4v1700248471851!5m2!1spt-PT!2sao"
                                    className="contact-page-google-map__one" allowfullscreen>
                                </iframe>                              
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}