import React from 'react';

export default class WhyChooseOne extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
              type: "iframe",
              mainClass: "mfp-fade",
              removalDelay: 160,
              preloader: true,
              fixedContentPos: false,
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="why-choose-one-sec">
                    <div className="container">
                        <div className="row">
                            {/* Start Why Choose One Content */}
                            <div className="col-xl-6">
                                <div className="why-choose-one__content">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <h6> Porque nos escolher</h6> <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title">Experiência Comprovada</h2>
                                        <p className="sec-title__text">Confie na SEPIMA para soluções especializadas com uma equipe experiente na vanguarda da indústria petrolífera. Nosso histórico fala por si.
                                        </p>
                                    </div>
    
                                    <div className="why-choose-one__content-top">
                                        <div className="img-box">
                                            <img src={publicUrl+"assets/images/resources/why-choose-v1-img1.jpg"} alt="" />
                                        </div>
                                        <div className="text-box">
                                            <p>Priorizamos a segurança em todas as etapas. Nossa abordagem rigorosa garante um ambiente de trabalho seguro, protegendo sua equipe e operações.</p>
                                        </div>
                                    </div>
    
                                    <div className="why-choose-one__content-bottom">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="why-choose-one__content-bottom-single">
                                                    <h2>Recomendação legal</h2>
                                                    <p>"Tranquilidade legal. Confie na SEPIMA para soluções industriais seguras e conformes, guiadas por práticas éticas e legislação atualizada."
                                                    </p>
                                                </div>
                                            </div>
    
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="why-choose-one__content-bottom-single">
                                                    <h2>Nossa Missão</h2>
                                                    <p>Nossa missão é elevar o desempenho e a eficiência de suas operações, proporcionando soluções de classe mundial adaptadas às necessidades exclusivas de cada cliente.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Why Choose One Content */}
    
                            {/* Start Why Choose One Img */}
                            <div className="col-xl-6">
                                <div className="why-choose-one__img">
                                    <div className="row">
                                        {/* Start Why Choose One Img Single */}
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="why-choose-one__img-single">
                                                <div className="img-box">
                                                    <img src={publicUrl+"assets/images/resources/why-choose-v1-img2.jpg"} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Why Choose One Img Single */}
    
                                        {/* Start Why Choose One Img Single */}
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="why-choose-one__img-single">
                                                <div className="img-box">
                                                    <img src={publicUrl+"assets/images/resources/why-choose-v1-img3.jpg"} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Why Choose One Img Single */}
    
                                        {/* Start Why Choose One Img Single */}
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="why-choose-one__img-single">
                                                <div className="img-box style2">
                                                    <img src={publicUrl+"assets/images/resources/why-choose-v1-img4.jpg"} alt="" />
                                                    <div className="video-box">
                                                        <a href="https://www.youtube.com/watch?v=Jt5R5H-4R6k"
                                                            className="video-popup">
                                                            <div className="why-choose-one__video-icon">
                                                                <span className="fa fa-play"></span>
                                                                <i className="ripple"></i>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Why Choose One Img Single */}
    
                                        {/* Start Why Choose One Img Single */}
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="why-choose-one__img-single">
                                                <div className="img-box">
                                                    <img src={publicUrl+"assets/images/resources/why-choose-v1-img5.jpg"} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Why Choose One Img Single */}
                                    </div>
                                </div>
                            </div>
                            {/* End Why Choose One Img */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}