import React from 'react';

export default class ServiceContactBox extends React.Component {
    render(){
        return (
            <>
                <div className="services-details__sidebar-single services-details-contact-box">
                    <div className="title">
                        <h2>Precisa de ajuda</h2>
                    </div>
                    <p>Entre em contacto com a nossa linha de atendimento </p>
                    <div className="number">
                        <a href="tel:123456789"><span className="icon-phone-call"></span>+(244) 939 202 289</a>
                    </div>
                </div>
            </>
        )
    }
}