import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import SingleGeneralContracting7 from '../components/single-service/SingleGeneralContracting7';
import BrandOne from '../components/brand/BrandOne';
import FooterOne from '../common/footer/FooterOne';

const GeneralContracting7 = () => {
    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Preparação de Superficie e Aplicação de Revestimento"
                currentPage="serviço" 
                Img="IMG-20230809-WA0095.jpg"
            />
            <SingleGeneralContracting7 />
            <BrandOne />
            <FooterOne />
        </>
    )
}

export default GeneralContracting7;