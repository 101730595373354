import React from 'react';
import {Link} from 'react-router-dom';
import BlogCategory from '../blog/BlogCategory';
import BlogLatestPost from '../blog/BlogLatestPost';
import BlogQuery from '../blog/BlogQuery';
import BlogSearch from '../blog/BlogSearch';
import BlogTags from '../blog/BlogTags';

export default class SingleBlogDetails extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="blog-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7">
                                <div className="blog-details__left">
                                    <div className="blog-details__img">
                                        <img src={publicUrl+"assets/images/blog/blog-list-img4.jpg"} alt="" />
                                        <div className="blog-details__date">
                                            <p> <span>14</span> <br /> Junho</p>
                                        </div>
                                    </div>
                                    <div className="blog-details__content">
                                        <ul className="blog-details__meta">
                                            <li><Link to={process.env.PUBLIC_URL + `/`}><i className="fa fa-bookmark"></i><span>Petroleo e Gás</span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/`}><i className="fa fa-user"></i><span>José Rocha</span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/`}><i className="fa fa-comments"></i><span>0 Comentários</span></Link></li>
                                            
                                        </ul>
                                        <h3 className="blog-details__title">Sustentabilidade na Indústria : Compromisso Ambiental</h3>
                                        <p className="blog-details__text-1">Na SEPIMA, reconhecemos que a sustentabilidade não é apenas uma escolha, mas uma responsabilidade inalienável. Como parte integrante da indústria, temos o compromisso de liderar o caminho em práticas sustentáveis que não apenas preservam o meio ambiente, mas também promovem uma abordagem responsável para as gerações futuras.</p>

                                        <h3 className="blog-details__content-two-title mt-5">
                                        Eficiência Energética e Redução de Emissões:</h3>

                                        <p className="blog-details__text-2">Um pilar central do nosso compromisso ambiental é a busca contínua pela eficiência energética e a redução de emissões. Investimos em tecnologias e práticas que minimizam o consumo de energia em nossas operações. A implementação de métodos inovadores não apenas reduz nossa pegada de carbono, mas também contribui para a eficiência geral da indústria.</p>
                                    </div>
                                    <div className="blog-details__content-two">
                                        <div className="blog-details__content-two-img">
                                            <img src={publicUrl+"assets/images/blog/blog-grid-img1.jpg"} alt="" />
                                        </div>
                                        <div className="blog-details__content-two-details">
                                            <h3 className="blog-details__content-two-title">Práticas Sustentáveis em Operações Diárias:</h3>
                                            <p className="blog-details__content-two-text-1">Na SEPIMA, a sustentabilidade não é uma palavra vazia; é uma filosofia incorporada em nossas operações diárias. Desde a escolha de materiais eco-friendly até a gestão eficiente de resíduos, buscamos práticas sustentáveis em todos os aspectos. Acreditamos que cada pequena ação contribui para um impacto positivo a longo prazo.</p>
                                            <p className="blog-details__content-two-text-2">Inovação em Soluções Sustentáveis:

Nossa abordagem sustentável é alimentada pela inovação. Buscamos constantemente novas tecnologias e métodos que reduzam nosso impacto ambiental. Do uso de materiais reciclados em nossos projetos à implementação de soluções de energia renovável, estamos comprometidos em liderar o caminho para um setor mais verde e responsável.

Preservação da Biodiversidade:

Reconhecemos a interconexão entre nosso trabalho e a biodiversidade. Assumimos a responsabilidade de minimizar qualquer impacto negativo em ecossistemas locais, incorporando práticas que preservem a biodiversidade e promovam um equilíbrio sustentável entre as atividades industriais e a natureza.

Educação e Parcerias para um Futuro Sustentável:

Além das práticas internas, a SEPIMA acredita no poder da educação e parcerias. Trabalhamos para aumentar a conscientização sobre questões ambientais, não apenas dentro da empresa, mas também colaborando com partes interessadas e comunidades locais. Estamos comprometidos em ser uma força positiva na promoção de mudanças sustentáveis além dos limites de nossas operações.

Conclusão:

Na SEPIMA, nosso compromisso ambiental é mais do que um slogan; é uma declaração de princípios que molda cada decisão e ação que tomamos. Estamos comprometidos em liderar não apenas pelo exemplo, mas pelo impacto duradouro que nossa abordagem sustentável pode ter na indústria. À medida que avançamos, continuaremos a ser impulsionados por um compromisso inabalável com a sustentabilidade, contribuindo para um futuro onde prosperidade econômica coexiste harmoniosamente com a preservação do nosso precioso ambiente.</p>
                                          
                                        </div>
                                    </div>
                                    
                         
                             

                                   
                                </div>
                            </div>

                            {/* Start Sidebar */}
                            <div className="col-xl-5 col-lg-5">
                                <div className="sidebar">
                                    <BlogSearch />
                                    {/* <BlogCategory /> */}
                                    <BlogLatestPost />
                                    <BlogQuery />
                                    {/* <BlogTags /> */}
                                </div>
                            </div>
                            {/* End Sidebar */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}