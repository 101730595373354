import React from 'react';
import {Link} from 'react-router-dom';

export default class FooterOne extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <footer className="footer-one-sec">
                    <div className="footer-one__pattern" style={{backgroundImage: 'url('+publicUrl+'assets/images/pattern/footer-v1-pattern.png)'}}></div>
                    <div className="footer-one__top">
                        <div className="footer-one__top-img"
                            style={{backgroundImage: 'url('+publicUrl+'assets/images/resources/footer-v1-img3.png)'}}></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__top-inner">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                                                <div className="footer-widget__column footer-widget__about">
                                                    <div className="footer-widget__about-logo">
                                                        <Link to="/"><img src={publicUrl+"assets/images/resources/logo-1.png"} alt="Logo" /></Link>
                                                    </div>

                                                    <p className="footer-widget__about-text">Na SEPIMA, estamos dedicados a superar expectativas e elevar padrões na indústria. Com um compromisso inabalável com a segurança, a excelência e a inovação, nossa equipe experiente está pronta para oferecer soluções especializadas que impulsionam o sucesso de nossos clientes.</p>

                                                    <div className="footer-widget__about-social-link">
                                                        <ul>
                                                            <li>
                                                                <a href="https://www.facebook.com/sepimangola">
                                                                    <span className="first icon-facebook-app-symbol"></span>
                                                                    <span className="second icon-facebook-app-symbol"></span>
                                                                </a>
                                                            </li>
                                                    

                                                            <li>
                                                                <a href="https://www.instagram.com/sepima_/">
                                                                    <span className="first icon-linkedin"></span>
                                                                    <span className="second icon-linkedin"></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
                                                <div className="footer-widget__column footer-widget__services">
                                                    <h2 className="footer-widget__title">Serviços</h2>
                                                    <ul className="footer-widget__services-list">
                                                        <li className="footer-widget__services-list-item"><Link to="/servico3">Meio de Acesso</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to="/servico1">Limpeza de dutos</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to="/servico2">Isolação termica</Link></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
                                                <div className="footer-widget__column footer-widget__explore">
                                                    <h2 className="footer-widget__title">Explore</h2>
                                                    <ul className="footer-widget__explore-list">
                                                        <li className="footer-widget__explore-list-item"><Link to="/sobrenos">Sobre nós</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="/artigos">Artigos</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="/servicos">Serviços</Link></li>
                                                        <li className="footer-widget__explore-list-item"><Link to="/contato">Contacto</Link></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                                                <div className="footer-widget__column footer-widget__posts">
                                                    <h2 className="footer-widget__title">Artigos Recentes</h2>
                                                    <div className="footer-widget__posts-list">
                                                        <ul>
                                                            <li>
                                                                <div className="img-box">
                                                                    <img src={publicUrl+"assets/images/blog/blog-v1-img1.jpg"} alt="" />
                                                                    <div className="overlay-icon">
                                                                        <Link to={process.env.PUBLIC_URL + `/artigo1`}><span className="icon-link"></span></Link>
                                                                    </div>
                                                                </div>

                                                                <div className="text-box">
                                                                    <p><Link to={process.env.PUBLIC_URL + `/artigo1`}>Inovações tecnologica em Acesso por Corda</Link></p>
                                                                    <span>12 Junho 2023</span>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="img-box">
                                                                    <img src={publicUrl+"assets/images/blog/blog-v1-img2.jpg"} alt="" />
                                                                    <div className="overlay-icon">
                                                                        <Link to={process.env.PUBLIC_URL + `/artigo2`}><span className="icon-link"></span></Link>
                                                                    </div>
                                                                </div>

                                                                <div className="text-box">
                                                                    <p><Link to={process.env.PUBLIC_URL + `/artigo2`}>Sustentabilidade na Indústria : Compromisso Ambiental</Link></p>
                                                                    <span>14 Julho 2023</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-one__bottom clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__bottom-inner">
                                        <div className="footer-one__bottom-text">
                                            <p>Direito de cópia &copy;2023. Todos os direitos reservados a <Link to="https://equalizador.ao">EQUALIZADOR</Link></p>
                                        </div>

                                        <div className="footer-one__bottom-list">
                                            <ul>
                                                <li><Link to="/about-one">Termos e Condições </Link></li>
                                                <li><Link to="/about-one"> Serviços</Link></li>
                                                <li><Link to="/about-one">Artigos</Link></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}