import React from 'react';
import {Link} from 'react-router-dom';

export default class FeatureFive extends React.Component {
    componentDidMount() {

        const $ = window.$;

        if ($(".tabs-box").length) {
            $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
              e.preventDefault();
              var target = $($(this).attr("data-tab"));
        
              if ($(target).is(":visible")) {
                return false;
              } else {
                target
                  .parents(".tabs-box")
                  .find(".tab-buttons")
                  .find(".tab-btn")
                  .removeClass("active-btn");
                $(this).addClass("active-btn");
                target
                  .parents(".tabs-box")
                  .find(".tabs-content")
                  .find(".tab")
                  .fadeOut(0);
                target
                  .parents(".tabs-box")
                  .find(".tabs-content")
                  .find(".tab")
                  .removeClass("active-tab");
                $(target).fadeIn(300);
                $(target).addClass("active-tab");
              }
            });
        }

        if ($(".feature-three__history-carousel").length) {
            $(".feature-three__history-carousel").owlCarousel({
              loop: false,
              margin: 30,
              nav: false,
              smartSpeed: 500,
              autoHeight: false,
              autoplay: true,
              dots: false,
              autoplayTimeout: 6000,
              navText: [
                '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
                '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
              ],
              responsive: {
                0: {
                  items: 1,
                },
                600: {
                  items: 1,
                },
                992: {
                  items: 2,
                },
                1200: {
                  items: 3,
                },
                1350: {
                  items: 3,
                },
              },
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="feature-three-sec clearfix">
                    <div className="feature-three__bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/backgrounds/features-v1-bg.jpg)'}}>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="feature-three__title">
                                    <div className="sec-title">
                                        <div className="sec-title__tagline">
                                            <h6>Mais sobre nós</h6> <span className="right"></span>
                                        </div>
                                        <h2 className="sec-title__title">Trajetória de Excelência </h2>
                                        <p className="sec-title__text">Da nossa fundação até os desafios superados, cada marco na nossa história reflete o compromisso duradouro com a excelência e inovação na indústria.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="feature-three__tab tabs-box">
                                    <div className="feature-three__tab-button">
                                        <ul className="tab-buttons clearfix">
                                            <li data-tab="#history" className="tab-btn active-btn">
                                                <h3>Nossa historia</h3>
                                            </li>
                                            <li data-tab="#growth " className="tab-btn">
                                                <h3>Crescimento</h3>
                                            </li>
                                            <li data-tab="#mission" className="tab-btn">
                                                <h3>Nossa Missão</h3>
                                            </li>
                                            <li data-tab="#vision" className="tab-btn">
                                                <h3>Nossa Visão</h3>
                                            </li>
                                        </ul>
                                    </div>


                                    <div className="tabs-content">
                                        {/* Start Tab */}
                                        <div className="tab active-tab" id="history">
                                            <div className="feature-three__tab-content-item">
                                                <div className="feature-three__history-carousel owl-carousel owl-theme">
                                                    {/* Start Feature Three History Single */}
                                                    <div className="feature-three__history-single">
                                                        <div className="history-text">
                                                            <h2>Origens e Fundação</h2>
                                                        </div>
                                                        <div className="date-box">
                                                            <h2>2015</h2>
                                                        </div>
                                                        <p>Em 2015, a SEPIMA teve sua origem, nascida da visão de pioneiros na indústria petrolífera. Desde o início, nossa missão era clara: fornecer soluções inovadoras e serviços especializados, elevando os padrões do setor.</p>
                                                    </div>
                                                    {/* End Feature Three History Single */}

                                                    {/* Start Feature Three History Single */}
                                                    <div className="feature-three__history-single">
                                                        <div className="history-text">
                                                            <h2>Crescimento e Estabelecimento</h2>
                                                        </div>
                                                        <div className="date-box">
                                                            <h2>2015 - 2017</h2>
                                                        </div>
                                                        <p>Durante o período de 2015 a 2017, a SEPIMA floresceu, expandindo suas operações e solidificando sua presença no mercado. Nesse tempo, construímos parcerias sólidas, investimos em tecnologias de ponta e estabelecemos as bases para o sucesso duradouro.</p>
                                                    </div>
                                                    {/* End Feature Three History Single */}

                                                    {/* Start Feature Three History Single */}
                                                    <div className="feature-three__history-single">
                                                        <div className="history-text">
                                                            <h2>Consolidação e Reconhecimento</h2>
                                                        </div>
                                                        <div className="date-box">
                                                            <h2>2017 - 2020</h2>
                                                        </div>
                                                        <p>Entre 2017 e 2020, a SEPIMA experimentou uma fase de consolidação, consolidando sua reputação como líder confiável na indústria. Durante esse período, conquistamos reconhecimento pelo nosso compromisso com a excelência, segurança e inovação.</p>
                                                    </div>
                                                    {/* End Feature Three History Single */}

                                                    {/* Start Feature Three History Single */}
                                                    <div className="feature-three__history-single">
                                                        <div className="history-text">
                                                            <h2>Inovação e Desafios Superados</h2>
                                                        </div>
                                                        <div className="date-box">
                                                            <h2>2020 - 2023</h2>
                                                        </div>
                                                        <p>De 2020 a 2023, a SEPIMA enfrentou desafios com resiliência, impulsionando a inovação em todos os aspectos de nossos serviços. Continuamos a superar expectativas, implementando práticas sustentáveis, e oferecendo soluções adaptadas às demandas em constante evolução da indústria. Este período marca um capítulo emocionante em nossa história de sucesso.</p>
                                                    </div>
                                                    {/* End Feature Three History Single */}

                                                 
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Tab */}

                                        {/* Start Tab */}
                                        <div className="tab" id="growth">
                                            <div className="feature-three__tab-content-item">
                                                <div className="tab-content-inner">
                                                    <div className="row">
                                                        <div className="col-xl-5 col-lg-6">
                                                            <div className="tab-content-inner-text">
                                                                <h2>Crescimento</h2>
                                                                <p>Na SEPIMA, o crescimento não é apenas uma métrica, é uma narrativa contínua de conquistas e superação. Desde nossas origens, cada passo à frente foi impulsionado pela busca incansável de inovação e pela habilidade de transformar desafios em oportunidades. Nosso crescimento não é apenas quantitativo; é a evolução constante de nossas capacidades, aprimoramento de serviços e expansão de nossa presença global. Com uma equipe dedicada e uma visão clara, nosso caminho de crescimento é moldado pela excelência, pela superação de metas e pela criação de um impacto duradouro na indústria. Este é mais do que um capítulo em nossa história; é a promessa contínua de alcançar novas alturas em cada aspecto de nossa jornada.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-7 col-lg-6">
                                                            <div className="tab-content-inner-img">
                                                                <img src={publicUrl+"assets/images/resources/feature-v3-img1.jpg"} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Tab */}

                                        {/* Start Tab */}
                                        <div className="tab" id="mission">
                                            <div className="feature-three__tab-content-item">
                                                <div className="tab-content-inner">
                                                    <div className="row">
                                                        <div className="col-xl-5 col-lg-6">
                                                            <div className="tab-content-inner-text">
                                                                <h2>Nossa Missão</h2>
                                                                <p>Ser reconhecida como uma empresa de referência no setor de petróleo e gás, criando valor econômico e social a médio e longo prazo, trazendo os benefícios do progresso e da inovação para um número crescente de pessoas.</p>
                                                                <div className="btn-box">
                                                                    <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn" data-text="Ler mais +">Ver mais +</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-7 col-lg-6">
                                                            <div className="tab-content-inner-img">
                                                                <img src={publicUrl+"assets/images/resources/why-choose-v1-img2.jpg"} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Tab */}

                                        {/* Start Tab */}
                                        <div className="tab" id="vision">
                                            <div className="feature-three__tab-content-item">
                                                <div className="tab-content-inner">
                                                    <div className="row">
                                                        <div className="col-xl-5 col-lg-6">
                                                            <div className="tab-content-inner-text">
                                                                <h2>Nossa Visão</h2>
                                                                <p>Aspiramos em ser reconhecidos nacional e internacional. Fornecendo um serviço dinâmico e inovador. Servindo como líder para o crescimento econômico, pessoal e regional.</p>
                                                                <div className="btn-box">
                                                                    <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn" data-text="Ler mais +">Ver mais +</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-7 col-lg-6">
                                                            <div className="tab-content-inner-img">
                                                                <img src={publicUrl+"assets/images/resources/why-choose-v1-img4.jpg"} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Tab */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}