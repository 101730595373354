import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import SingleGeneralContracting3 from '../components/single-service/SingleGeneralContracting3';
import BrandOne from '../components/brand/BrandOne';
import FooterOne from '../common/footer/FooterOne';

const GeneralContracting3 = () => {
    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Meio de Acesso em Altura"
                currentPage="serviço" 
                Img="services-v1-img3.jpg"
            />
            <SingleGeneralContracting3 />
            <BrandOne />
            <FooterOne />
        </>
    )
}

export default GeneralContracting3;