import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import SingleGeneralContracting2 from '../components/single-service/SingleGeneralContracting2';
import BrandOne from '../components/brand/BrandOne';
import FooterOne from '../common/footer/FooterOne';

const GeneralContracting2 = () => {
    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Isolação Térmica e Soldadura"
                currentPage="serviço" 
                Img="services-v1-img2.jpg"
            />
            <SingleGeneralContracting2 />
            <BrandOne />
            <FooterOne />
        </>
    )
}

export default GeneralContracting2;