import React, { useState, useRef, useEffect } from 'react';

import Spinner from "../Spinner";
import AuthUser from '../AuthUser';

const FormularioPerfilCandidatura = () => {

    // eslint-disable-next-line no-unused-vars
    const { http, user, url_root } = AuthUser(); 
    const [loading, setLoading] = useState(false);

    const [funcoes, setfuncoes] = useState([]);
    const [localizacoes, setLocalizacoes] = useState([]);
    const [localizacaoSelecionada, setLocalizacaoSelecionada] = useState(-1);
    const [funcoesSelecionadasIDs, setFuncoesSelecionadas] = useState([]);
    const [fotografia, setSelectedFile] = useState(null);

    const [doc_bilhete, setSelectedFileBilhete] = useState(null);
    const [doc_cv, setSelectedFileCV] = useState(null);
    const [doc_academico, setSelectedFileAcademico] = useState(null);
    const [doc_certificacoes, setSelectedFileCertificacao] = useState(null);

    const [name, setName] = useState('');

    //eslint-disable-next-line no-unused-vars
    const [telefone, setTelefone] = useState('');
    const [nivel_academico, setAcademico] = useState('');
    const [data_nasc, setDataNasc] = useState(new Date());
    const [genero, setGenero] = useState(1);
    const [endereco, setEndereco] = useState('');
    const [habilidades, setHabilidade] = useState('');

    //eslint-disable-next-line no-unused-vars
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [titulo_academico1, setTACA1] = useState(1);
    const [titulo_academico2, setTACA2] = useState(2);
    const [titulo_academico3, setTACA3] = useState(3);
    const [inputs, setInputs] = useState([]);

    const [utilizador, setUtilizador] = useState(null);

    const [fotografiaPreview, setFotografiaPreview] = useState(null);

    const handleInputsValueChanged = (event) => {
        const name = event.target.name;
        const value = event.target.value;
    
        setInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
        }));
      };

    useEffect(() => {
    
        const fetchData = async () => {
          try {
    
            const userResponse = await http.get('/utilizador/' + user?.id);
            console.log('User data:', userResponse.data.utilizador);
    
            setUtilizador(userResponse.data?.utilizador);

            getFuncoes();
              getPaises();
    
              setInputs(userResponse.data.utilizador);
              setGenero(userResponse.data.utilizador.genero);
              setFotografiaPreview(userResponse.data.utilizador.fotografia);
    
              setLocalizacaoSelecionada(userResponse.data.utilizador.nacionalidade_id);   
              
    
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
    
      }, [user?.id]);
 
    const getFuncoes = async () => {
        try {
            setLoading(true);

            const resultado = await http.get('/funcaos_ativas');
            setfuncoes(resultado.data.funcaos);
            // console.log(resultado.data.funcaos);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log('Utilizador não autenticado.');
            } else {
                console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
            }
        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };

    const getPaises = async () => {
        try {
            setLoading(true);

            const resultado2 = await http.get('/paises');
            setLocalizacoes(resultado2.data.regioes);
            console.log(resultado2.data.regioes);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log('Utilizador não autenticado.');
            } else {
                console.log('Ocorreu um erro inesperado. Por favor, tente mais tarde.');
            }
        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };


    const handleFileChange = (e) => {
        // Lógica para processar a imagem, se necessário
        setSelectedFile(e.target.files[0]);

        // Restante do código
    };


    const triggerFileChange = () => {

        const element = document.querySelector('#input-fotografia');
        // Check if the element exists before triggering the click event
        if (element) {
            // Trigger a click event
            element.click();
        }
        // Restante do código
    };


    const triggerFileChangeDocBilhete = () => {

        const element = document.querySelector('#input-doc-bilhete');
        // Check if the element exists before triggering the click event
        if (element) {
            // Trigger a click event
            element.click();
        }
        // Restante do código
    };

    const handleFileChangeBilhete = (e) => {
        // Lógica para processar a imagem, se necessário
        setSelectedFileBilhete(e.target.files[0]);

        // Restante do código
    };


    const triggerFileChangeDocCV = () => {

        const element = document.querySelector('#input-doc-cv');
        // Check if the element exists before triggering the click event
        if (element) {
            // Trigger a click event
            element.click();
        }
        // Restante do código
    };

    const handleFileChangeCV = (e) => {
        // Lógica para processar a imagem, se necessário
        setSelectedFileCV(e.target.files[0]);

        // Restante do código
    };

    const triggerFileChangeDocAcademico = () => {

        const element = document.querySelector('#input-doc-academico');
        // Check if the element exists before triggering the click event
        if (element) {
            // Trigger a click event
            element.click();
        }
        // Restante do código
    };


    const handleFileChangeAcademico = (e) => {
        // Lógica para processar a imagem, se necessário
        setSelectedFileAcademico(e.target.files[0]);

        // Restante do código
    };

    const triggerFileChangeDocCertificacao = () => {

        const element = document.querySelector('#input-doc-certificacao');
        // Check if the element exists before triggering the click event
        if (element) {
            // Trigger a click event
            element.click();
        }
        // Restante do código
    };

    const handleFileChangeCertificacao = (e) => {
        // Lógica para processar a imagem, se necessário
        setSelectedFileCertificacao(e.target.files[0]);

        // Restante do código
    };


    const [formData, setFormData] = useState({
        dadosPessoais: {},
        formacaoAcademica: {},
        experienciaProfissional: {},
        habilidadesCompetencias: {},
        disponibilidade: {},
        anexos: {},
    });

    const [currentStep, setCurrentStep] = useState(1);
    const formRef = useRef(null);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const scrollToForm = () => {
        /*if (formRef.current) {
          formRef.current.scrollIntoView({ behavior: 'smooth' });
        }*/
    };

    const handleInputChange = (step, field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [step]: {
                ...prevData[step],
                [field]: value,
            },
        }));
    };

    const titulosEtapas = [
        'Funções A Candidatar-se',
        'Dados Pessoais',
        'Formação Acadêmica',
        'Experiência Profissional',
        'Habilidades e Competências',
        'Disponibilidade',
        'Anexos',
    ];

    const renderSeparador = () => {

        return (
            <ul className="etapas-separador">
                {titulosEtapas.map((titulo, index) => (
                    <li key={index} className={currentStep === index + 1 ? 'active' : ''} onClick={() => {
                        setCurrentStep(index + 1);
                        scrollToForm();
                    }}>
                        {titulo}
                    </li>
                ))}
            </ul>
        );
    };

    // Function to handle the "checkBoxes from table" checkbox change
    const handleCheckBoxChange = () => {

        // Get all the "check-pagamento" checkboxes
        const checkboxes = document.querySelectorAll(".check-funcao");

        // Create a new array to store the selected collaborator IDs
        const selectedFuncoes = [];

        console.log(funcoesSelecionadasIDs?.length);

        if (funcoesSelecionadasIDs?.length >= 3) {

            alert('3 FUNÇÕES É O LIMITE DEFINIDO, APENAS AS TRÊS PRIMEIRAS SELECIONADAS SERÃO REGISTADAS');

        }
        else {


            // Set the checked state of each checkbox to match "checkgeral"
            checkboxes.forEach((checkbox) => {

                if (checkbox.checked) {
                    // Add the ID to the selectedPagamentos array
                    const funcaoId = checkbox.dataset.id;
                    console.log('função ID selecionada:', funcaoId);
                    selectedFuncoes.push(funcaoId);
                }
            });

            // Update the state with the selected collaborator IDs
            setFuncoesSelecionadas(selectedFuncoes);

        }

    }

    const renderOptionNacionalidades = (localizacoes) => {
        return localizacoes?.map((localizacao) => (
            <React.Fragment key={localizacao.id}>
                <option value={localizacao.id}>{localizacao.nome.toUpperCase()}</option>
            </React.Fragment>
        ));
    };



    const handleSelectNacionalidadeChange = (valor) => {

        setLocalizacaoSelecionada(valor);

    }


    const validateEmail = (email) => {
        // Use a regular expression for basic email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };


    const submitForm = async (e) => {

        e?.preventDefault();

        try {


            // Basic form validation
            if (inputs.name?.length < 2) {
                setLoading(false);

                // Show a error toast
                alert('O nome deve ter pelo menos dois caracteres.');
            } else
                if (!validateEmail(inputs.email)) {

                    setLoading(false);
                    // Show a error toast
                    alert('O email deve ser válido.');
                } else
                            await uploadUtilizador();


        } catch (error) {
            if (error.response && error.response.status === 401) {


                // Show a error toast
                alert('Utilizador não autenticado.');

            } else {

                // Show a error toast
                alert('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

            }
        } finally {
            setLoading(false);
        }

    };


    const uploadUtilizador = async () => {


        try {

            const formData = new FormData();
            formData.append('id', inputs.id);
            formData.append('name', inputs.name);
            formData.append('email', inputs.email);
            formData.append('telefone', inputs.telefone);
            
            if(fotografia)
            formData.append('fotografia', fotografia);

            formData.append('data_nasc', inputs.data_nasc);
            
            formData.append('genero', genero);

            formData.append('nivel_academico', nivel_academico);
            
            formData.append('endereco', inputs.endereco);
            formData.append('habilidades', inputs.habilidades);

            formData.append('nacionalidade_id', localizacaoSelecionada);

            formData.append('disponibilidade_data', inputs.disponibilidade_data);
            formData.append('disponibilidade_hora1', inputs.disponibilidade_hora1);
            formData.append('disponibilidade_hora2', inputs.disponibilidade_hora2);

            formData.append('titulo_academico1', inputs.titulo_academico1);
            formData.append('titulo_academico2', inputs.titulo_academico2);
            formData.append('titulo_academico3', inputs.titulo_academico3);

            formData.append('titulo_descricao1', inputs.titulo_descricao1);
            formData.append('titulo_descricao2', inputs.titulo_descricao2);
            formData.append('titulo_descricao3', inputs.titulo_descricao3);

            formData.append('ano_formatura1', inputs.ano_formatura1);
            formData.append('ano_formatura2', inputs.ano_formatura2);
            formData.append('ano_formatura3', inputs.ano_formatura3);

            formData.append('instituicao1', inputs.instituicao1);
            formData.append('instituicao2', inputs.instituicao2);
            formData.append('instituicao3', inputs.instituicao3);

            formData.append('exp_empresa1', inputs.exp_empresa1);
            formData.append('exp_empresa2', inputs.exp_empresa2);
            formData.append('exp_empresa3', inputs.exp_empresa3);

            formData.append('exp_cargo1', inputs.exp_cargo1);
            formData.append('exp_cargo2', inputs.exp_cargo2);
            formData.append('exp_cargo3', inputs.exp_cargo3);

            formData.append('exp_responsabilidade1', inputs.exp_responsabilidade1);
            formData.append('exp_responsabilidade2', inputs.exp_responsabilidade2);
            formData.append('exp_responsabilidade3', inputs.exp_responsabilidade3);

            formData.append('exp_ano1', inputs.exp_ano1);
            formData.append('exp_ano2', inputs.exp_ano2);
            formData.append('exp_ano3', inputs.exp_ano3);

            formData.append('competencias', inputs.competencias);
            formData.append('certificacoes', inputs.certificacoes);

            if(doc_bilhete)
            formData.append('doc_bilhete', doc_bilhete);
            
            if(doc_cv)
            formData.append('doc_cv', doc_cv);
            
            if(doc_academico)
            formData.append('doc_academico', doc_academico);
            
            if(doc_certificacoes)
            formData.append('doc_certificacoes', doc_certificacoes);

            formData.append('funcoes', funcoesSelecionadasIDs);

            const response = await http.post('/update/candidato', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            // Show a success toast
            alert(response.data.message);

            setLoading(false);            

        } catch (error) {
            console.error(error);

            setLoading(false);

            alert('Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.');
        }

    };

    const renderFormStep = (step) => {
        switch (step) {
            case 1:
                return (
                    <div className="contact-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="contact-page__content">
                                        <div className="title">
                                            <h2>{titulosEtapas[currentStep - 1]}</h2>
                                            <span>Selecione no máximo três (3) funções em que pretende candidatar-se. </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <form className="comment-one__form contact-form-validated" novalidate="novalidate" onSubmit={(e) => submitForm(e)}>



                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12" title="Vaga em que se aplica">
                                                    <div className="comment-form__input-box">

                                                        <ul className="funcoes_checklist">
                                                            {
                                                                funcoes?.map((cdata, index) => (

                                                                    <li key={index}>
                                                                        <label>
                                                                            <input type="checkbox" name="funcao" value={cdata?.id} onChange={handleCheckBoxChange} data-id={cdata.id} className='check-funcao' />
                                                                            &nbsp;
                                                                            {cdata?.designacao}
                                                                        </label>
                                                                    </li>

                                                                ))

                                                            }

                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">

                                                {currentStep > 1 && (
                                                    <div className="col-xl-4 col-lg-4">       <button onClick={handlePrevStep} className="thm-btn comment-form__btn" data-text="Anterior +"
                                                        data-loading-text="Please wait...">Anterior +</button></div>
                                                )}

                                                {currentStep < 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={handleNextStep} className="thm-btn comment-form__btn" data-text="Próximo +"
                                                            data-loading-text="Please wait...">Próximo +</button></div>
                                                )}

                                                {currentStep === 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button >Salvar</button></div>
                                                )}


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="contact-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="contact-page__content">
                                        <div className="title">
                                            <h2>{titulosEtapas[currentStep - 1]}</h2>
                                            <span>O seu endereço de e-mail não sera partilhado com ninguém. campos obrigatorios estão marcados com * </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <form className="comment-one__form contact-form-validated" novalidate="novalidate" onSubmit={(e) => submitForm(e)}>
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-6" title="Nome">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Nome" name='name' value={inputs.name} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-6" title="E-mail">
                                                    <div className="comment-form__input-box">
                                                        <input type="email" placeholder="Email" name='email' value={inputs.email} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-6" title="E-mail">
                                                    <div className="comment-form__input-box">
                                                        <select
                                                            name="nacionalidade"
                                                            onChange={(e) => handleSelectNacionalidadeChange(e.target.value)}
                                                            value={localizacaoSelecionada}
                                                            className="form-control select"
                                                        >
                                                            <option value={-1}>Selecione a nacionalidade</option>
                                                            {renderOptionNacionalidades(localizacoes)}

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col-xl-4 col-lg-4" title="Genero">
                                                    <div className="comment-form__input-box">
                                                        <select className="form-control" name='genero' onChange={e => setGenero(e.target.value)} >
                                                            <option value="-1">Escolha o genero</option>
                                                            <option value="2">Feminino</option>
                                                            <option value="1">Masculino</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-4" title="Telefone">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Telefone" name="telefone" value={inputs.telefone} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-4" title="Data de nascimento">
                                                    <div className="comment-form__input-box">
                                                        <input type="date" placeholder="Data nascimento" name="data_nasc" className="form-control" value={inputs.data_nasc} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-7 col-lg-6" title="Endereço">
                                                    <div className="comment-form__input-box">
                                                        <textarea placeholder="Endereço" name="endereco" value={inputs.endereco} onChange={handleInputsValueChanged}></textarea>
                                                    </div>
                                                </div>

                                                <div className="col-xl-5 col-lg-6" title="Fotografia">
                                                    <div className="comment-form__input-box text-center py-3 bg-light">

                                                        <input type="file" id='input-fotografia' className='d-none' onChange={handleFileChange} />

                                                        <div className="image-uploads">
                                                            {fotografia ? (
                                                                <>
                                                                    <img width={150} height={150}
                                                                        src={URL.createObjectURL(fotografia)}
                                                                        alt="Pré-visualização"
                                                                    />
                                                                    <h6 onClick={triggerFileChange}><label>Carregar fotografia</label></h6>
                                                                </>
                                                            ) : (
                                                                <h6 onClick={triggerFileChange}><label>Carregar fotografia</label></h6>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">




                                                {currentStep > 1 && (
                                                    <div class="row"><div className="col-xl-12 col-lg-12">       <button onClick={handlePrevStep} className="thm-btn comment-form__btn" data-text="Anterior +"
                                                        data-loading-text="Please wait...">Anterior +</button></div></div>
                                                )}

                                                {currentStep < 8 && (
                                                    <div class="row"><div className="col-xl-12 col-lg-12">
                                                        <button onClick={handleNextStep} className="thm-btn comment-form__btn" data-text="Próximo +"
                                                            data-loading-text="Please wait...">Próximo +</button></div></div>
                                                )}

                                                {currentStep === 8 && (
                                                    <div class="row"><div className="col-xl-12 col-lg-12">
                                                        <button onClick={() => console.log('Dados do formulário submetidos:', formData)}>Salvar</button></div></div>
                                                )}


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="contact-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="contact-page__content">
                                        <div className="title">
                                            <h2>{titulosEtapas[currentStep - 1]}</h2>
                                            <span>Campos obrigatorios estão marcados com * </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <form className="comment-one__form contact-form-validated" novalidate="novalidate" onSubmit={(e) => submitForm(e)}>

                                            <div className="row">
                                                <div className="col-xl-2 col-lg-2" title="Titulo academico 1">
                                                    <div className="comment-form__input-box">
                                                        <select value={titulo_academico1} onChange={e => setTACA1(e.target.value)}>
                                                            <option value="1">Técnico médio</option>
                                                            <option value="2">Bacharel</option>
                                                            <option value="3">Licenciatura</option>
                                                            <option value="4">Doutoramento</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8" title="Especialidade">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Especialização Ex. Ciêntista da computação" value={inputs.titulo_descricao1} name='titulo_descricao1' onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2" title="Data de termino [ 1 ]">
                                                    <div className="comment-form__input-box">
                                                        <input type="date" placeholder="data" name='ano_formatura1' value={inputs.ano_formatura1} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>

                                                <div className="col-xl-12 col-lg-12" title="Instituição">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="instituição [ 1 ]" name='instituicao1' value={inputs.instituicao1} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>


                                            </div>
                                            <hr></hr>

                                            <div className="row">
                                                <div className="col-xl-2 col-lg-2" title="Titulo academico 1">
                                                    <div className="comment-form__input-box">
                                                        <select value={titulo_academico2} onChange={e => setTACA2(e.target.value)}>
                                                            <option value="1">Técnico médio</option>
                                                            <option value="2">Bacharel</option>
                                                            <option value="3">Licenciatura</option>
                                                            <option value="4">Doutoramento</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8" title="Especialidade">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Especialização Ex. Ciêntista da computação" value={inputs.titulo_descricao2} name='titulo_descricao2' onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2" title="Data de termino [ 2 ]">
                                                    <div className="comment-form__input-box">
                                                        <input type="date" placeholder="data" name='ano_formatura2' value={inputs.ano_formatura2} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12" title="Instituição">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="instituição [ 2 ]" name='instituicao2' value={inputs.instituicao2} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>

                                            </div>
                                            <hr></hr>

                                            <div className="row">
                                                <div className="col-xl-2 col-lg-2" title="Titulo academico 1">
                                                    <div className="comment-form__input-box">
                                                        <select value={titulo_academico3} onChange={e => setTACA3(e.target.value)}>
                                                            <option value="1">Técnico médio</option>
                                                            <option value="2">Bacharel</option>
                                                            <option value="3">Licenciatura</option>
                                                            <option value="4">Doutoramento</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8" title="Especialidade">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Especialização Ex. Ciêntista da computação" value={inputs.titulo_descricao3} name='titulo_descricao3' onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2" title="Data de termino [ 3 ]">
                                                    <div className="comment-form__input-box">
                                                        <input type="date" placeholder="data" name='ano_formatura3' value={inputs.ano_formatura3} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12" title="Instituição">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Especialização Ex. Ciêntista da computação" value={inputs.instituicao3} name='instituicao3' onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>

                                            <div className="row">

                                                {currentStep > 1 && (
                                                    <div className="col-xl-4 col-lg-4">       <button onClick={handlePrevStep} className="thm-btn comment-form__btn" data-text="Anterior +"
                                                        data-loading-text="Please wait...">Anterior +</button></div>
                                                )}

                                                {currentStep < 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={handleNextStep} className="thm-btn comment-form__btn" data-text="Próximo +"
                                                            data-loading-text="Please wait...">Próximo +</button></div>
                                                )}

                                                {currentStep === 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={() => console.log('Dados do formulário submetidos:', formData)}>Salvar</button></div>
                                                )}


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="contact-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="contact-page__content">
                                        <div className="title">
                                            <h2>{titulosEtapas[currentStep - 1]}</h2>
                                            <span>Campos obrigatorios estão marcados com * </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <form className="comment-one__form contact-form-validated" novalidate="novalidate" onSubmit={(e) => submitForm(e)}>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6" title="Nome da empresa [1]">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Nome da empresa" name='exp_empresa1' value={inputs.exp_empresa1} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6" title="Função que desempenhou [1]">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Função que desempenhou" name='exp_cargo1' value={inputs.exp_cargo1} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-10 col-lg-10" title="Descrição das responsabilidades [1]">
                                                    <div className="comment-form__input-box">
                                                        <textarea  placeholder="Descrição das responsabilidades" value={inputs.exp_responsabilidade1} name='exp_responsabilidade1' onChange={handleInputsValueChanged}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2" title="Data fim de contrato [1]">
                                                    <div className="comment-form__input-box">
                                                        <input type="date" placeholder="Data fim de contrato" name='exp_ano1' value={inputs.exp_ano1} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr></hr>

                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6" title="Nome da empresa [1]">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Nome da empresa" name='exp_empresa2' value={inputs.exp_empresa2} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6" title="Função que desempenhou [1]">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Função que desempenhou" name='exp_cargo2' value={inputs.exp_cargo2} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-10 col-lg-10" title="Descrição das responsabilidades [1]">
                                                    <div className="comment-form__input-box">
                                                        <textarea placeholder="Descrição das responsabilidades" name='exp_responsabilidade2' value={inputs.exp_responsabilidade2} onChange={handleInputsValueChanged}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2" title="Data fim de contrato [1]">
                                                    <div className="comment-form__input-box">
                                                        <input type="date" placeholder="Data fim de contrato" name='exp_ano2' value={inputs.exp_ano2} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr></hr>

                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6" title="Nome da empresa [1]">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Nome da empresa" name='exp_empresa3' value={inputs.exp_empresa3} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6" title="Função que desempenhou [1]">
                                                    <div className="comment-form__input-box">
                                                        <input type="text" placeholder="Função que desempenhou" name='exp_cargo3' value={inputs.exp_cargo3} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-10 col-lg-10" title="Descrição das responsabilidades [1]">
                                                    <div className="comment-form__input-box">
                                                        <textarea  placeholder="Descrição das responsabilidades" name='exp_responsabilidade3' value={inputs.exp_responsabilidade3} onChange={handleInputsValueChanged}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2" title="Data fim de contrato [1]">
                                                    <div className="comment-form__input-box">
                                                        <input type="date" placeholder="Data fim de contrato" name='exp_ano3' value={inputs.exp_ano3} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr></hr>


                                            <div className="row">

                                                {currentStep > 1 && (
                                                    <div className="col-xl-4 col-lg-4">       <button onClick={handlePrevStep} className="thm-btn comment-form__btn" data-text="Anterior +"
                                                        data-loading-text="Please wait...">Anterior +</button></div>
                                                )}

                                                {currentStep < 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={handleNextStep} className="thm-btn comment-form__btn" data-text="Próximo +"
                                                            data-loading-text="Please wait...">Próximo +</button></div>
                                                )}

                                                {currentStep === 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={() => console.log('Dados do formulário submetidos:', formData)}>Salvar</button></div>
                                                )}


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className="contact-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="contact-page__content">
                                        <div className="title">
                                            <h2>{titulosEtapas[currentStep - 1]}</h2>
                                            <span>Campos obrigatorios estão marcados com * </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <form className="comment-one__form contact-form-validated" novalidate="novalidate" onSubmit={(e) => submitForm(e)}>

                                            <div className="row">

                                                <div className="col-xl-12 col-lg-12" title="Habilidades técnicas">
                                                    <div className="comment-form__input-box">
                                                        <textarea className="form-control" name='habilidades' value={inputs.habilidades} onChange={handleInputsValueChanged} placeholder='Habilidades técnicas'></textarea>
                                                    </div>
                                                </div>

                                            </div>

                                            <hr></hr>

                                            <div className="row">

                                                <div className="col-xl-12 col-lg-12" title="Competências relevantes">
                                                    <div className="comment-form__input-box">
                                                        <textarea type="text" placeholder="Comptetências relevantes" name='competencias' value={inputs.competencias} onChange={handleInputsValueChanged}></textarea>
                                                    </div>
                                                </div>

                                            </div>

                                            <hr></hr>

                                            <div className="row">

                                                <div className="col-xl-12 col-lg-12" title="Certificações">
                                                    <div className="comment-form__input-box">
                                                        <textarea type="text" placeholder="Certificações" name='certificacoes' value={inputs.certificacoes} onChange={handleInputsValueChanged}></textarea>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">

                                                {currentStep > 1 && (
                                                    <div className="col-xl-4 col-lg-4">       <button onClick={handlePrevStep} className="thm-btn comment-form__btn" data-text="Anterior +"
                                                        data-loading-text="Please wait...">Anterior +</button></div>
                                                )}

                                                {currentStep < 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={handleNextStep} className="thm-btn comment-form__btn" data-text="Próximo +"
                                                            data-loading-text="Please wait...">Próximo +</button></div>
                                                )}

                                                {currentStep === 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={() => console.log('Dados do formulário submetidos:', formData)}>Salvar</button></div>
                                                )}


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 6:
                return (
                    <div className="contact-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="contact-page__content">
                                        <div className="title">
                                            <h2>{titulosEtapas[currentStep - 1]}</h2>
                                            <span>Campos obrigatorios estão marcados com * </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <form className="comment-one__form contact-form-validated" novalidate="novalidate" onSubmit={(e) => submitForm(e)}>
                                            <div className="row">
                                                <div className="col-xl-8 col-lg-6" title="Data disponivel">
                                                    <div className="comment-form__input-box">
                                                        <input type="date" value={inputs.disponibilidade_data} name='disponibilidade_data' onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-6" title="Horario entrada">
                                                    <div className="comment-form__input-box">
                                                        <input type="time" value={inputs.disponibilidade_hora1} name='disponibilidade_hora1' onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-6" title="Horario saida">
                                                    <div className="comment-form__input-box">
                                                        <input type="time" name='disponibilidade_hora2' value={inputs.disponibilidade_hora2} onChange={handleInputsValueChanged} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">

                                                {currentStep > 1 && (
                                                    <div className="col-xl-4 col-lg-4">       <button onClick={handlePrevStep} className="thm-btn comment-form__btn" data-text="Anterior +"
                                                        data-loading-text="Please wait...">Anterior +</button></div>
                                                )}

                                                {currentStep < 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={handleNextStep} className="thm-btn comment-form__btn" data-text="Próximo +"
                                                            data-loading-text="Please wait...">Próximo +</button></div>
                                                )}

                                                {currentStep === 8 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={() => console.log('Dados do formulário submetidos:', formData)}>Salvar</button></div>
                                                )}


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 7:
                return (
                    <div className="contact-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="contact-page__content">
                                        <div className="title">
                                            <h2>{titulosEtapas[currentStep - 1]}</h2>
                                            <span>Campos obrigatorios estão marcados com * </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <form className="comment-one__form contact-form-validated" novalidate="novalidate" onSubmit={(e) => submitForm(e)}>
                                            <div className="row">
                                                <div className="col-xl-3 col-lg-3" title="Carregar Bilhete ou Passaporte">
                                                    <div className="comment-form__input-box">
                                                        <div className="comment-form__input-box text-center py-3 bg-light">

                                                            <input type="file" id='input-doc-bilhete' className='d-none' onChange={handleFileChangeBilhete} />

                                                            <div className="image-uploads">
                                                                {doc_bilhete ? (
                                                                    <>
                                                                        <img width={150} height={150}
                                                                            src={URL.createObjectURL(doc_bilhete)}
                                                                            alt="ficheiro carregado"
                                                                        />
                                                                        <h6 onClick={triggerFileChangeDocBilhete}><label>Carregar Bilhete o Passaporte</label></h6>
                                                                    </>
                                                                ) : (
                                                                    <h6 onClick={triggerFileChangeDocBilhete}><label>Carregar Bilhete Ou Passaporte</label></h6>
                                                                )}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-3 col-lg-3" title="Carregar CV">
                                                    <div className="comment-form__input-box">
                                                        <div className="comment-form__input-box text-center py-3 bg-light">

                                                            <input type="file" id='input-doc-cv' className='d-none' onChange={handleFileChangeCV} />

                                                            <div className="image-uploads">
                                                                {doc_cv ? (
                                                                    <>
                                                                        <img width={150} height={150}
                                                                            src={URL.createObjectURL(doc_cv)}
                                                                            alt="CV carregado"
                                                                        />
                                                                        <h6 onClick={triggerFileChangeDocCV}><label>Carregar CV</label></h6>
                                                                    </>
                                                                ) : (
                                                                    <h6 onClick={triggerFileChangeDocCV}><label>Carregar CV</label></h6>
                                                                )}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-3 col-lg-3" title="Carregar Documentos Academicos">
                                                    <div className="comment-form__input-box">
                                                        <div className="comment-form__input-box text-center py-3 bg-light">

                                                            <input type="file" id='input-doc-academico' className='d-none' onChange={handleFileChangeAcademico} />

                                                            <div className="image-uploads">
                                                                {doc_academico ? (
                                                                    <>
                                                                        <img width={150} height={150}
                                                                            src={URL.createObjectURL(doc_academico)}
                                                                            alt="ficheiro carregado"
                                                                        />
                                                                        <h6 onClick={triggerFileChangeDocAcademico}><label>Carregar Certificados ou Declarações Acádemicas</label></h6>
                                                                    </>
                                                                ) : (
                                                                    <h6 onClick={triggerFileChangeDocAcademico}><label>Carregar Certificados ou Declarações Acádemicas</label></h6>
                                                                )}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-xl-3 col-lg-3" title="Carregar Documentos Academicos">
                                                    <div className="comment-form__input-box">
                                                        <div className="comment-form__input-box text-center py-3 bg-light">

                                                            <input type="file" id='input-doc-certificacao' className='d-none' onChange={handleFileChangeCertificacao} />

                                                            <div className="image-uploads">
                                                                {doc_certificacoes ? (
                                                                    <>
                                                                        <img width={150} height={150}
                                                                            src={URL.createObjectURL(doc_certificacoes)}
                                                                            alt="ficheiro carregado"
                                                                        />
                                                                        <h6 onClick={triggerFileChangeDocCertificacao}><label>Carregar Certificações</label></h6>
                                                                    </>
                                                                ) : (
                                                                    <h6 onClick={triggerFileChangeDocCertificacao}><label>Carregar Certificações</label></h6>
                                                                )}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>



                                               
                                            </div>
                                            <div className="row">

                                                {currentStep > 1 && (
                                                    <div className="col-xl-4 col-lg-4">       <button onClick={handlePrevStep} className="thm-btn comment-form__btn" data-text="Anterior +"
                                                        data-loading-text="Please wait...">Anterior +</button></div>
                                                )}

                                                {currentStep === 7 && (
                                                    <div className="col-xl-4 col-lg-4">
                                                        <button onClick={() => console.log('Dados do formulário submetidos:', formData)} data-text="Salvar"  className='thm-btn comment-form__btn'>Salvar</button></div>
                                                )}


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (


        <div className="mt-5">

            {loading ? <Spinner /> :

                <>

                    {renderSeparador()}

                    {renderFormStep(currentStep)}

                </>}



        </div>


    );
};

export default FormularioPerfilCandidatura;
