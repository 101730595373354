import React from 'react';
import {Link} from 'react-router-dom';
import ButtonBox from './ButtonBox';
import ServiceContactBox from './ServiceContactBox';
import ServiceSearch from './ServiceSearch';

export default class SingleGeneralContracting extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($(".tabs-box").length) {
            $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
              e.preventDefault();
              var target = $($(this).attr("data-tab"));
        
              if ($(target).is(":visible")) {
                return false;
              } else {
                target
                  .parents(".tabs-box")
                  .find(".tab-buttons")
                  .find(".tab-btn")
                  .removeClass("active-btn");
                $(this).addClass("active-btn");
                target
                  .parents(".tabs-box")
                  .find(".tabs-content")
                  .find(".tab")
                  .fadeOut(0);
                target
                  .parents(".tabs-box")
                  .find(".tabs-content")
                  .find(".tab")
                  .removeClass("active-tab");
                $(target).fadeIn(300);
                $(target).addClass("active-tab");
              }
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="services-details-page">
                    <div className="container">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-7 col-lg-7">
                                <div className="services-details__content">
                                    <div className="services-details__img1">
                                        <img src={publicUrl+"assets/images/services/IMG-20230809-WA0095.jpg"} alt="" />
                                    </div>
                                    <div className="services-details__text-box1">
                                        <div className="title">
                                            <h2>Meio de Acesso em Altura: Elevando a Eficiência com Segurança e Precisão</h2>
                                        </div>
                                        <p className="text-1">O meio de acesso em altura é uma componente vital em uma variedade de setores, desempenhando um papel essencial em operações que requerem inspeções, manutenção e instalações em locais de difícil acesso. </p>

                                        <p className="text-2">A SEPIMA, com sua expertise e compromisso com padrões de segurança excepcionais, oferece um serviço de meio de acesso em altura que não apenas alcança novas alturas, mas também garante eficiência e precisão em cada projeto. Vamos explorar três fases fundamentais na planificação deste serviço essencial.</p>
                                    </div>

                                    <div className="services-details__text-box2">
                                        <div className="row">
                                            <div className="col-xl-5">
                                                <div className="services-details__text-box2-img">
                                                    <img src={publicUrl+"assets/images/services/services-details-img2.jpg"} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-xl-7">
                                                <div className="services-details__text-box2-content">
                                                    <div className="title">
                                                        <h2>A planificação</h2>
                                                    </div>
                                                    <div className="text">
                                                        <p>Vamos considerar alguns aspetos na nossa planificação deste serviço</p>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <div className="icon">
                                                                <span className="icon-tick"></span>
                                                            </div>

                                                            <div className="text">
                                                                <p>Avaliação de Riscos e Requisitos</p>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="icon">
                                                                <span className="icon-tick"></span>
                                                            </div>

                                                            <div className="text">
                                                                <p>Seleção e Preparação de Equipamentos</p>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="icon">
                                                                <span className="icon-tick"></span>
                                                            </div>

                                                            <div className="text">
                                                                <p>Implementação com Foco em Segurança </p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="services-details__text-box3">
                                        <p>Em resumo, o serviço de meio de acesso em altura da SEPIMA não é apenas uma solução prática, mas uma abordagem holística para garantir que tarefas desafiadoras em alturas elevadas sejam concluídas com máxima eficiência e, acima de tudo, com segurança intransigente.</p>

                                        <ul>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-tick"></span>
                                                </div>

                                                <div className="text">
                                                    <p>Através de uma planificação cuidadosa e execução precisa, a SEPIMA continua a ser líder na indústria, elevando padrões e desafiando limites.
                                                    </p>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="icon">
                                                    <span className="icon-tick"></span>
                                                </div>

                                                <div className="text">
                                                    <p>O plano também considera a minimização de impactos ambientais e a conformidade com regulamentações pertinentes.
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>


                                 
                                </div>
                            </div>
                            {/* End Services Details Content */}

                           {/* Start Services Details Sidebar */}
                           <div className="col-xl-5 col-lg-5">
                                <div className="services-details__sidebar">
                                    <ServiceSearch />

                                    {/* Start Services Details Sidebar Single */}
                                    <div className="services-details__sidebar-single services-details-category">
                                        <div className="title">
                                            <h2>Outros serviços</h2>
                                        </div>
                                        <ul className="services-details-category-list">
                                            <li><Link to={process.env.PUBLIC_URL + `/servico2`} className="active">Isolação Térmica e Soldadura
                                                <span className="icon-right-arrow"></span></Link>
                                            </li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico3`}>Meio de Acesso em Altura<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico4`}>Sistemas De Ventilação ( HVAC )<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico5`}>Acesso por Corda<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico6`}>Fornecimento de Equipamento<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico7`}>Preparação de Superficie e Aplicação de Revestimento<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico1`}>Limpeza de duto<span className="icon-right-arrow"></span></Link></li>
                                        </ul>
                                    </div>
                                    {/* End Services Details Sidebar Single */}

                                    {/* <ButtonBox /> */}
                                    <ServiceContactBox />
                                </div>
                            </div>
                            {/* End Services Details Sidebar */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}