import React from 'react';
import {Link} from 'react-router-dom';

export default class BlogLatestPost extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <div className="sidebar__single sidebar__post">
                    <h3 className="sidebar__title">Artigos recentes</h3>
                    <div className="sidebar__post-box">
                        <div className="sidebar__post-single">
                            <div className="sidebar-post__img">
                                <img src={publicUrl+"assets/images/blog/blog-v1-img1.jpg"} alt="" />
                            </div>
                            <div className="sidebar__post-content-box">
                                <h3><Link to="/artigo1">Inovações : Acesso por Corda </Link></h3>
                            </div>
                        </div>
                        <div className="sidebar__post-single">
                            <div className="sidebar-post__img">
                                <img src={publicUrl+"assets/images/blog/blog-v1-img2.jpg"} alt="" />
                            </div>
                            <div className="sidebar__post-content-box">
                                <h3><Link to="/artigo2">Sustentabilidade na Indústria : Compromisso Ambiental </Link></h3>
                            </div>
                        </div>
                        <div className="sidebar__post-single">
                            <div className="sidebar-post__img">
                                <img src={publicUrl+"assets/images/blog/blog-v1-img3.jpg"} alt="" />
                            </div>
                            <div className="sidebar__post-content-box">
                                <h3><Link to="/artigo3">Os Benefícios da Isolação Térmica</Link></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}