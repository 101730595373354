import React from 'react';
import {Link} from 'react-router-dom';
import ButtonBox from './ButtonBox';
import ServiceContactBox from './ServiceContactBox';
import ServiceSearch from './ServiceSearch';

export default class SingleGeneralContracting extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($(".tabs-box").length) {
            $(".tabs-box .tab-buttons .tab-btn").on("click", function (e) {
              e.preventDefault();
              var target = $($(this).attr("data-tab"));
        
              if ($(target).is(":visible")) {
                return false;
              } else {
                target
                  .parents(".tabs-box")
                  .find(".tab-buttons")
                  .find(".tab-btn")
                  .removeClass("active-btn");
                $(this).addClass("active-btn");
                target
                  .parents(".tabs-box")
                  .find(".tabs-content")
                  .find(".tab")
                  .fadeOut(0);
                target
                  .parents(".tabs-box")
                  .find(".tabs-content")
                  .find(".tab")
                  .removeClass("active-tab");
                $(target).fadeIn(300);
                $(target).addClass("active-tab");
              }
            });
        }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="services-details-page">
                    <div className="container">
                        <div className="row">
                            {/* Start Services Details Content */}
                            <div className="col-xl-7 col-lg-7">
                                <div className="services-details__content">
                                    <div className="services-details__img1">
                                        <img src={publicUrl+"assets/images/services/services-v1-img1.jpg"} alt="" />
                                    </div>
                                    <div className="services-details__text-box1">
                                        <div className="title">
                                            <h2>Serviço de limpeza de Duto</h2>
                                        </div>
                                        <p className="text-1">O serviço de limpeza de dutos oferecido pela SEPIMA representa um compromisso inabalável com a integridade e eficiência das instalações industriais. Nossa equipe altamente especializada utiliza métodos avançados para realizar limpezas detalhadas, removendo acúmulos que podem afetar o desempenho e a qualidade do ar. Ao empregar técnicas inovadoras, garantimos que os dutos permaneçam livres de resíduos prejudiciais, promovendo um ambiente seguro e operacionalmente eficiente.</p>

                                        <p className="text-2">Na SEPIMA, a limpeza de dutos não é apenas um serviço; é um componente essencial para o sucesso contínuo de nossos clientes na indústria petrolífera.</p>
                                    </div>

                                    <div className="services-details__text-box2">
                                        <div className="row">
                                            <div className="col-xl-5">
                                                <div className="services-details__text-box2-img">
                                                    <img src={publicUrl+"assets/images/services/services-details-img2.jpg"} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-xl-7">
                                                <div className="services-details__text-box2-content">
                                                    <div className="title">
                                                        <h2>A planificação</h2>
                                                    </div>
                                                    <div className="text">
                                                        <p>A planificação para a limpeza de dutos envolve várias fases cruciais para garantir eficiência e segurança.</p>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <div className="icon">
                                                                <span className="icon-tick"></span>
                                                            </div>

                                                            <div className="text">
                                                                <p>Inspeção e Avaliação</p>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="icon">
                                                                <span className="icon-tick"></span>
                                                            </div>

                                                            <div className="text">
                                                                <p> Desenvolvimento do Plano de Limpeza  </p>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="icon">
                                                                <span className="icon-tick"></span>
                                                            </div>

                                                            <div className="text">
                                                                <p>Execução e Monitoramento </p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="services-details__text-box3">
                                        <p>Antes de iniciar a limpeza, é essencial realizar uma inspeção completa dos dutos. Isso inclui a avaliação do estado atual, identificação de possíveis obstruções, acúmulos de resíduos e danos estruturais. A compreensão precisa das condições iniciais orienta o desenvolvimento de estratégias de limpeza específicas. </p>

                                        <ul>
                                            <li>
                                                <div className="icon">
                                                    <span className="icon-tick"></span>
                                                </div>

                                                <div className="text">
                                                    <p>Com base na avaliação inicial, é elaborado um plano de limpeza detalhado. Isso inclui a seleção de métodos de limpeza adequados, escolha de equipamentos especializados e a definição de procedimentos operacionais seguros. 
                                                    </p>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="icon">
                                                    <span className="icon-tick"></span>
                                                </div>

                                                <div className="text">
                                                    <p>O plano também considera a minimização de impactos ambientais e a conformidade com regulamentações pertinentes.
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>


                                 
                                </div>
                            </div>
                            {/* End Services Details Content */}

                            {/* Start Services Details Sidebar */}
                            <div className="col-xl-5 col-lg-5">
                                <div className="services-details__sidebar">
                                    <ServiceSearch />

                                    {/* Start Services Details Sidebar Single */}
                                    <div className="services-details__sidebar-single services-details-category">
                                        <div className="title">
                                            <h2>Outros serviços</h2>
                                        </div>
                                        <ul className="services-details-category-list">
                                            <li><Link to={process.env.PUBLIC_URL + `/servico2`} className="active">Isolação Térmica e Soldadura
                                                <span className="icon-right-arrow"></span></Link>
                                            </li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico3`}>Meio de Acesso em Altura<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico4`}>Sistemas De Ventilação ( HVAC )<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico5`}>Acesso por Corda<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico6`}>Fornecimento de Equipamento<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico7`}>Preparação de Superficie e Aplicação de Revestimento<span className="icon-right-arrow"></span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/servico1`}>Limpeza de duto<span className="icon-right-arrow"></span></Link></li>
                                        </ul>
                                    </div>
                                    {/* End Services Details Sidebar Single */}

                                    {/* <ButtonBox /> */}
                                    <ServiceContactBox />
                                </div>
                            </div>
                            {/* End Services Details Sidebar */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}