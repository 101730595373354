import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import ContactFormLogin from "../components/contact/ContactFormLogin";
import BrandOne from "../components/brand/BrandOne";
import FooterOne from "../common/footer/FooterOne";

const LoginPage = () => {
    return (
        <>
            <HeaderThree />
            <ContactFormLogin />
            <BrandOne />
            {/* <AppointmentOne /> */}
            <FooterOne />
        </>
    )
}

export default LoginPage;