import React from 'react';
import {Link} from 'react-router-dom';
import BlogCategory from '../blog/BlogCategory';
import BlogLatestPost from '../blog/BlogLatestPost';
import BlogQuery from '../blog/BlogQuery';
import BlogSearch from '../blog/BlogSearch';
import BlogTags from '../blog/BlogTags';

export default class SingleBlogDetails extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="blog-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7">
                                <div className="blog-details__left">
                                    <div className="blog-details__img">
                                        <img src={publicUrl+"assets/images/blog/blog-v1-img2B.jpg"} alt="" />
                                        <div className="blog-details__date">
                                            <p> <span>11</span> <br /> Julho</p>
                                        </div>
                                    </div>
                                    <div className="blog-details__content">
                                        <ul className="blog-details__meta">
                                            <li><Link to={process.env.PUBLIC_URL + `/`}><i className="fa fa-bookmark"></i><span>Petroleo e Gás</span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/`}><i className="fa fa-user"></i><span>José Rocha</span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/`}><i className="fa fa-comments"></i><span>0 Comentários</span></Link></li>
                                            
                                        </ul>
                                        <h3 className="blog-details__title">Os Benefícios da Isolação Térmica: Potencializando Eficiência e Sustentabilidade na Indústria</h3>
                                        <p className="blog-details__text-1">A isolação térmica é mais do que uma simples camada de proteção; é uma estratégia essencial para impulsionar a eficiência operacional e promover práticas sustentáveis na indústria. Na SEPIMA, reconhecemos os inúmeros benefícios que a isolação térmica oferece e estamos comprometidos em compartilhar esses conhecimentos para promover um ambiente industrial mais eficiente e eco-friendly.</p>

                                        <h3 className="blog-details__content-two-title mt-5">
                                        1. Eficiência Energética:</h3>

                                        <p className="blog-details__text-2">Um dos benefícios mais notáveis da isolação térmica é a melhoria da eficiência energética. Ao criar uma barreira eficaz contra a transferência de calor, a isolação térmica reduz a necessidade de aquecimento ou refrigeração constante. Isso não apenas resulta em menor consumo de energia, mas também se traduz em significativas economias nos custos operacionais a longo prazo.</p>
                                    </div>
                                    <div className="blog-details__content-two">
                                        <div className="blog-details__content-two-img">
                                            <img src={publicUrl+"assets/images/blog/blog-grid-img9.jpg"} alt="" />
                                        </div>
                                        <div className="blog-details__content-two-details">
                                            <h3 className="blog-details__content-two-title">2. Controle de Temperatura:</h3>
                                            <p className="blog-details__content-two-text-1">A isolação térmica proporciona um controle preciso da temperatura interna de instalações industriais. Ao manter ambientes internos mais estáveis, a isolação térmica não apenas aumenta o conforto dos trabalhadores, mas também preserva a integridade de equipamentos sensíveis à temperatura, prolongando sua vida útil e reduzindo a necessidade de manutenção.</p>
                                            <p className="blog-details__content-two-text-2">3. Redução de Emissões de Gases de Efeito Estufa:

                                            Ao diminuir a demanda por energia, a isolação térmica contribui diretamente para a redução das emissões de gases de efeito estufa. A redução do consumo de combustíveis fósseis não apenas alinha as operações industriais com objetivos ambientais mais amplos, mas também coloca as empresas em conformidade com regulamentações ambientais mais rigorosas.

4. Economia Financeira Sustentável:

Investir em isolação térmica é uma estratégia financeiramente sustentável a longo prazo. Embora o investimento inicial possa ser significativo, os benefícios em termos de economia de energia e custos operacionais resultam em um rápido retorno sobre o investimento. A isolação térmica é uma aposta segura para empresas que buscam eficiência financeira e ambiental.

5. Conforto do Ambiente de Trabalho:

Além dos benefícios econômicos e ambientais, a isolação térmica contribui para um ambiente de trabalho mais confortável. Mantendo temperaturas internas estáveis, cria-se um ambiente mais agradável para os funcionários, resultando em maior produtividade e satisfação no trabalho.

6. Durabilidade e Proteção de Ativos:

A isolação térmica não apenas otimiza o desempenho operacional, mas também oferece proteção duradoura para ativos industriais. Ao reduzir a exposição a extremos de temperatura, a isolação térmica minimiza o desgaste e a corrosão de equipamentos, prolongando sua vida útil e reduzindo a necessidade de substituições frequentes.

Em resumo, a isolação térmica é um investimento multifacetado que não apenas aprimora a eficiência operacional, mas também desempenha um papel vital na promoção da sustentabilidade ambiental. Na SEPIMA, estamos comprometidos em destacar os benefícios da isolação térmica e em implementar soluções que impulsionem a eficiência e reduzam o impacto ambiental em todas as nossas operações industriais.                                  
                                            </p>
                                          
                                        </div>
                                    </div>

                                   
                                </div>
                            </div>

                            {/* Start Sidebar */}
                            <div className="col-xl-5 col-lg-5">
                                <div className="sidebar">
                                    <BlogSearch />
                                    {/* <BlogCategory /> */}
                                    <BlogLatestPost />
                                    <BlogQuery />
                                    {/* <BlogTags /> */}
                                </div>
                            </div>
                            {/* End Sidebar */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}