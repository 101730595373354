import React , { useState  } from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import ContactMap from "../components/contact/ContactMap";
import ContactForm from "../components/contact/ContactForm";
import BrandOne from "../components/brand/BrandOne";
import FooterOne from "../common/footer/FooterOne";
import AppointmentOne from '../components/appointment/AppointmentOne';
import FormularioNovaCandidatura from '../components/candidaturas/FormularioNovaCandidatura';


import { toast, ToastContainer } from 'react-toastify';

const CandidaturaNova = () => {


    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Preencha o formulario abaixo"
                currentPage="Recrutamento" 
                Img="1160867507.jpg"
            />

           
            <FormularioNovaCandidatura />

          
            
            <FooterOne />
            
        </>
    )
}

export default CandidaturaNova;