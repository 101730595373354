import React, { useState } from 'react';
import { Link } from "react-router-dom";

import AuthUser from '../../components/AuthUser';

const Nav = () => {
  const [activeMenu, setActiveMenu] = useState('');

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const {getToken, logout, token}=AuthUser();

  return (
    <div className="main-menu text-center">
      <nav>
        <ul className="main-menu__list">
          <li className={`dropdown ${activeMenu === 'inicio' ? 'current' : ''}`}>
            <Link to={process.env.PUBLIC_URL + `/`} onClick={() => handleMenuClick('inicio')} id='menu_inicio'>
              Inicio
            </Link>
          </li>
          <li className={`dropdown ${activeMenu === 'sobrenos' ? 'current' : ''}`}>
            <Link to={process.env.PUBLIC_URL + `/sobrenos`} onClick={() => handleMenuClick('sobrenos')}>
              Sobre nós
            </Link>
          </li>
          <li className={`dropdown ${activeMenu === 'servicos' ? 'current' : ''}`}>
            <Link to={process.env.PUBLIC_URL + `/servicos`} onClick={() => handleMenuClick('servicos')}>
              Serviços
            </Link>
          </li>
          <li className={`dropdown ${activeMenu === 'artigos' ? 'current' : ''}`}>
            <Link to={process.env.PUBLIC_URL + `/artigos`} onClick={() => handleMenuClick('artigos')}>
              Artigos
            </Link>
          </li>

          

          

          {
                                        token != undefined ? <><li className={`dropdown ${activeMenu === 'candidaturas' ? 'current' : ''}`}>
                                        <Link to={process.env.PUBLIC_URL + `/perfil`} onClick={() => handleMenuClick('perfil')} id='menu_perfil'>
                                          Perfil
                                        </Link>
                                      </li>  </> : <><li className={`dropdown ${activeMenu === 'candidaturas' ? 'current' : ''}`}>
            <Link to={process.env.PUBLIC_URL + `/recrutamento`} onClick={() => handleMenuClick('candidaturas')}>
              Recrutamento
            </Link>
          </li></>
                                    }

          <li>
            <Link to={process.env.PUBLIC_URL + `/contato`} onClick={() => handleMenuClick('contato')} id='menu_contacto'>
              Contactos
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
