import React from 'react';
import {Link} from 'react-router-dom';

export default class ServiceFour extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="services-one-sec services-one-sec--services">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Serviços</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title">Nossos serviços</h2>
                        </div>

                        <div className="row">
                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/services-v1-img1.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico1`}>Serviço de limpeza de Duto</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>A limpeza de dutos é uma parte essencial da manutenção para garantir o fluxo eficiente de produtos petrolíferos e prevenir obstruções.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico1`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/services-v1-img2.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico2`}>Isolação Térmica e Soldadura</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>A isolação térmica é crucial em operações petrolíferas para garantir a segurança e a eficiência, A soldagem é uma técnica comum usada na construção e manutenção de estruturas na indústria petrolífera.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico2`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/services-v1-img3.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico3`}>Meio de Acesso em Altura</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p> Além do acesso por corda, pode incluir outras técnicas e equipamentos para trabalhar em alturas, garantindo a segurança dos trabalhadores.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico3`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}



 {/* Start Services One Single */}
 <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/IMG-20230809-WA0014.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico4`}>Sistemas De Ventilação ( HVAC )</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>HVAC (Heating, Ventilation, and Air Conditioning) refere-se a sistemas de aquecimento, ventilação e ar condicionado.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico4`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/IMG-20230809-WA0072.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico5`}>Acesso por Corda</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Acesso por corda é uma técnica utilizada para alcançar áreas de difícil acesso sem o uso de andaimes.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico5`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                            {/* Start Services One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/IMG-20230809-WA0010.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico6`}>Fornecimento de Equipamento</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>  Isso pode incluir o fornecimento de equipamentos de segurança, ferramentas especializadas e outros dispositivos necessários para realizar trabalhos em ambientes petrolíferos.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico6`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                              {/* Start Services One Single */}
                              <div className="col-xl-4 col-lg-4">
                                <div className="services-one__single">
                                    <div className="services-one__single-img">
                                        <img src={publicUrl+"assets/images/services/IMG-20230809-WA0095.jpg"} alt="" />
                                    </div>

                                    <div className="services-one__single-content">
                                        <div className="services-one__single-content-title">
                                            <h2><Link to={process.env.PUBLIC_URL + `/servico7`}>Preparação de Superficie e Aplicação de Revestimento</Link></h2>
                                        </div>

                                        <div className="services-one__single-content-bottom">
                                            <p>Isso envolve a preparação de superfícies e a aplicação de revestimentos protetores em estruturas para evitar corrosão e desgaste.</p>

                                            <div className="services-one__single-btn">
                                                <Link to={process.env.PUBLIC_URL + `/servico7`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* End Services One Single */}

                        </div>
                    </div>
                </section>
            </>
        )
    }
}