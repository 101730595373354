import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import SingleBlogDetails3 from '../components/single-blog/SingleBlogDetails3';
import BrandOne from '../components/brand/BrandOne';
import FooterOne from '../common/footer/FooterOne';

const BlogDetails = () => {
    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Os Benefícios da Isolação Térmica"
                currentPage="Artigo" 
                Img="blog-v1-img3.jpg"
            />
            <SingleBlogDetails3 />
            <BrandOne />
            <FooterOne />
        </>
    )
}

export default BlogDetails;