import React , { useState  } from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import ContactMap from "../components/contact/ContactMap";
import ContactForm from "../components/contact/ContactForm";
import BrandOne from "../components/brand/BrandOne";
import FooterOne from "../common/footer/FooterOne";
import AppointmentOne from '../components/appointment/AppointmentOne';
import FormularioPerfilCandidatura from '../components/candidaturas/FormularioPerfilCandidatura';


import { toast, ToastContainer } from 'react-toastify';

const Perfil = () => {


    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Atualize o seu perfil de candidatura"
                currentPage="Candidatura" 
                Img="1160867507.jpg"
            />

           
            <FormularioPerfilCandidatura />
          
            
            <FooterOne />
            
        </>
    )
}

export default Perfil;