import React from 'react';
import {Link} from "react-router-dom";
import LogoWhite from './LogoWhite';
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import OffCanvasStyleOne from './OffCanvasStyleOne';
import SearchButton from './SearchButton';

import AuthUser from '../../components/AuthUser';

const HeaderThree = () => {


      /* start custom logout */

  const {getToken, logout, token}=AuthUser();

    const logoutUser = ()=> {

      console.log("Clicou para sair");

      if(token != undefined){

        logout();

         const element = document.querySelector('#menu_inicio');
            // Check if the element exists before triggering the click event
            if (element) {
                // Trigger a click event
                element.click();
            }
      }else {


         const element = document.querySelector('#menu_inicio');
            // Check if the element exists before triggering the click event
            if (element) {
                // Trigger a click event
                element.click();
            }
      }

    }


  /* end custom logout */


    return (
        <>
            <header className="main-header main-header-one clearfix">
                <div className="main-header-one__wrapper">

                    <div className="main-header-one__top">
                        <div className="container-fluid">
                            <div className="main-header-one__top-inner">

                                <div className="main-header-one__left">
                                    <LogoWhite />
                                </div>

                                <div className="main-header-one__middle">
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <span className="icon-pin"></span>
                                            </div>

                                            <div className="text">
                                                <span>Localização</span>
                                                <p>Luanda, Angola</p>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="icon">
                                                <span className="icon-email"></span>
                                            </div>

                                            <div className="text">
                                                <span>Suporte Online</span>
                                                <p className="email"><a href="mailto:needhelp@company.com">geral@sepima.com</a>
                                                </p>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="icon">
                                                <span className="icon-phone-call"></span>
                                            </div>

                                            <div className="text">
                                                <span>Ligue</span>
                                                <p className="number"><a href="tel:123456789">+(244) 939 202 289</a></p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="main-header-one__right">
                                    <OffCanvasStyleOne />
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="main-header-one__bottom">
                        <div className="container-fluid">
                            <div className="main-header-one__bottom-inner">

                                <div className="main-header-one__bottom-left">
                                    <nav className="main-menu main-menu--1">
                                        <div className="main-menu__inner">
                                            <MobileMenu />
                                            <div className="stricky-one-logo">
                                                <LogoWhite />
                                            </div>
                                            <Nav />
                                        </div>
                                    </nav>
                                </div>


                                <div className="main-header-one__bottom-right">
                                    <div className="search-box">
                                        <SearchButton />
                                    </div>                                     


                                    {
                                        token != undefined ? <><div className="cart-btn" title="Sair">
                                       
                                        <span className="fa fa-times" onClick={()=>logoutUser()}></span>
                                    
                                </div>  </> : <><div className="cart-btn" title="fazer login">
                                        <Link to={`${process.env.PUBLIC_URL}/login`}>
                                            <span className="fa fa-user"></span>
                                        </Link>
                                    </div></>
                                    }

                                     

                                    <div className="main-header-one__bottom-right-btn">
                                        <Link  className="thm-btn" data-text="Orçamento">Solicitar orçamento +</Link>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </header>

            
        </>
    )
}

export default HeaderThree;