import React from 'react';
import {Link} from 'react-router-dom';

export default class HeroThree extends React.Component {
    componentDidMount() {

        const $ = window.$;
        
        if ($('.slider-carousel').length) {
            $('.slider-carousel').owlCarousel({
              animateOut: 'fadeOut',
              animateIn: 'fadeIn',
              loop: true,
              margin: 0,
              nav: false,
              singleItem: true,
              smartSpeed: 500,
              autoplay: true,
              autoplayTimeout: 6000,
              navText: ['<span class="icon-right-arrow"></span>', '<span class="icon-right-arrow"></span>'],
              responsive: {
                0: {
                  items: 1
                },
                600: {
                  items: 1
                },
                1024: {
                  items: 1
                }
              }
            });
          }
          
    }
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="slider-one-sec">
                    <div className="slider-carousel owl-carousel owl-theme">
                        {/* Start Slider One Single */}
                        <div className="slider-one__single">
                            <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/slider/slider-v1-img1.jpg)'}}>
                            </div>
                            <div className="container">
                                <div className="row clearfix">
                                    <div className="col-xl-12">
                                        <div className="slider-one__single-content">
                                            <h3>Segurança de trabalho.</h3>
                                            <h2>Segurança em  <br /> Primeiro Lugar</h2>
                                            <p>Nosso compromisso irrevogável é garantir a segurança de cada projeto.<br /> Implementamos práticas rigorosas,  treinamento especializado e tecnologias avançadas <br /> para criar um ambiente de trabalho seguro e protegido para nossa equipe e suas operações. </p>
                                            <div className="btn-box">
                                                <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Slider One Single */}

                        {/* Start Slider One Single */}
                        <div className="slider-one__single">
                            <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/slider/slider-v1-img2.jpg)'}}>
                            </div>
                            <div className="container">
                                <div className="row clearfix">
                                    <div className="col-xl-12">
                                        <div className="slider-one__single-content">
                                            <h3>Isolação termica</h3>
                                            <h2>Excelência em Manutenção <br />e Isolação Térmica</h2>
                                            <p>Especializados em soldagem e isolação térmica para garantir o desempenho <br /> otimizado de suas instalações com eficiência e durabilidade.</p>
                                            <div className="btn-box">
                                                <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Slider One Single */}

                        {/* Start Slider One Single */}
                        <div className="slider-one__single">
                            <div className="image-layer" style={{backgroundImage: 'url('+publicUrl+'assets/images/slider/slider-v1-img3.jpg)'}}>
                            </div>
                            <div className="container">
                                <div className="row clearfix">
                                    <div className="col-xl-12">
                                        <div className="slider-one__single-content">
                                            <h3>Acesso Vertical Seguro</h3>
                                            <h2>Acesso por Corda <br />e Soluções em Altura</h2>
                                            <p>Profissionais qualificados utilizando técnicas avançadas de acesso por corda para inspeções,<br /> manutenção e instalações em ambientes desafiadores da indústria petrolífera.</p>
                                            <div className="btn-box">
                                                <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn" data-text="Ler mais +">Ler mais +</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Slider One Single */}
                    </div>
                </section>
            </>
        )
    }
}