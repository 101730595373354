
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import Spinner from '../Spinner';
import AuthUser from '../AuthUser'; 


 const ContactFormLogin = () => { 

    const { http, setToken } = AuthUser();

    const [email, setEmail] = useState('');
    const [permissaoVer, setPermissaoVer] = useState(9);
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem('reloaded')) {
            localStorage.setItem('reloaded', true);
            window.location.reload();
        }
    }, []);
    

 /*start model custom login code*/




 const submitForm = async () => {
     try {
         setLoading(true);

         const res = await http.post('/login', { email: email, password: password });

         setToken(res.data.user, res.data.permissoes, res.data.access_token, res.data.empresa, res.data.banca_permissao);

         alert('Credências aceite com sucesso!');

         setPermissaoVer(res.data.user.nivel_id);

         const element = document.querySelector('#menu_inicio');
            // Check if the element exists before triggering the click event
            if (element) {
                // Trigger a click event
                element.click();
            }

     } catch (error) {
         if (error.response && error.response.status === 401) {
             alert('Credências inválidas, tente novamente!');
         } else {
             alert('Algo correu mal, tente novamente mais tarde!');
         }
     } finally {
         setLoading(false);
     }
 };



 /*end model custom login code*/


        return (
            <>
                <div className="contact-page">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="contact-page__content">
                                    <div className="title">
                                        <h2>Faça seu login</h2>
                                    </div>

                                    <div className="contact-page__content-single">
                                        <div className="contact-page__content-single-inner">
                                            <div className="icon-box">
                                                <span className="icon-pin"></span>
                                            </div>

                                            <div className="content-box">
                                                <h2>Entrar</h2>
                                                <p>Ao entrar com as suas credencias, você podera alterar a sua informação de candidatura sempre que necessario</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="contact-page__form">
                                    <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate">
                                        <div className="row">                                         
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="comment-form__input-box">
                                                    <input type="email" placeholder="Email" name="email" onChange={e => setEmail(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="comment-form__input-box">
                                                    <input type="password" placeholder="Senha" name="password" onChange={e => setPassword(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12">
                                               
                                            {loading ? <Spinner /> :

                                                    <><button className="thm-btn comment-form__btn" data-text="Entrar +"
                                                    type="button" data-loading-text="Por favor espere..." onClick={submitForm}>Entrar +</button>
                                                    </>}


                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    
}

export default ContactFormLogin;