import React from 'react';
import {Link} from 'react-router-dom';
import BlogCategory from '../blog/BlogCategory';
import BlogLatestPost from '../blog/BlogLatestPost';
import BlogQuery from '../blog/BlogQuery';
import BlogSearch from '../blog/BlogSearch';
import BlogTags from '../blog/BlogTags';

export default class SingleBlogDetails extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="blog-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7">
                                <div className="blog-details__left">
                                    <div className="blog-details__img">
                                        <img src={publicUrl+"assets/images/blog/blog-v2-img5.jpg"} alt="" />
                                        <div className="blog-details__date">
                                            <p> <span>12</span> <br /> Maio</p>
                                        </div>
                                    </div>
                                    <div className="blog-details__content">
                                        <ul className="blog-details__meta">
                                            <li><Link to={process.env.PUBLIC_URL + `/`}><i className="fa fa-bookmark"></i><span>Petroleo e Gás</span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/`}><i className="fa fa-user"></i><span>José Rocha</span></Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/`}><i className="fa fa-comments"></i><span>0 Comentários</span></Link></li>
                                            
                                        </ul>
                                        <h3 className="blog-details__title">Inovações em Acesso por Corda: Elevando Padrões na Indústria com Segurança e Eficiência</h3>
                                        <p className="blog-details__text-1">O acesso por corda, há muito tempo uma ferramenta essencial em operações industriais, está passando por uma revolução com as mais recentes inovações na indústria. Na SEPIMA, comprometemo-nos a liderar esse avanço, adotando tecnologias de ponta para oferecer soluções de acesso por corda que não apenas superam desafios, mas também estabelecem novos padrões de segurança e eficiência.</p>

                                        <h3 className="blog-details__content-two-title mt-5">
                                        A Evolução Tecnológica:</h3>

                                        <p className="blog-details__text-2">À medida que a tecnologia avança, o acesso por corda não é exceção. As novas técnicas e equipamentos estão permitindo operações mais complexas e desafiadoras a serem realizadas com uma precisão sem precedentes. Os sistemas de ancoragem aprimorados, as cordas de alta resistência e os dispositivos de segurança inteligentes estão transformando a maneira como abordamos tarefas em alturas elevadas.</p>
                                    </div>
                                    <div className="blog-details__content-two">
                                        <div className="blog-details__content-two-img">
                                            <img src={publicUrl+"assets/images/blog/blog-grid-img9.jpg"} alt="" />
                                        </div>
                                        <div className="blog-details__content-two-details">
                                            <h3 className="blog-details__content-two-title">Eficiência sem Comprometer a Segurança</h3>
                                            <p className="blog-details__content-two-text-1">A inovação no acesso por corda está intrinsecamente ligada ao aumento da eficiência sem comprometer a segurança. A SEPIMA investe em treinamento contínuo para nossa equipe, garantindo que estejam atualizados com as últimas práticas e tecnologias. Isso não apenas permite a execução mais rápida de tarefas, mas também assegura que cada operação seja realizada com os mais altos padrões de segurança.</p>
                                            <p className="blog-details__content-two-text-2">Tecnologia para Inspeções Precisas:

                                            Uma área em que as inovações são mais evidentes é nas inspeções. Câmeras avançadas e tecnologias de imagem permitem inspeções visuais detalhadas, proporcionando uma visão nítida de áreas difíceis de alcançar. Isso não apenas simplifica o processo de inspeção, mas também contribui para uma análise mais precisa das condições estruturais.

Sustentabilidade e Responsabilidade Ambiental:

Inovações em acesso por corda também estão alinhadas com a sustentabilidade. A SEPIMA busca constantemente métodos e materiais que minimizem o impacto ambiental de suas operações. A redução do uso de equipamentos pesados e a escolha de práticas sustentáveis são parte integrante de nossa abordagem inovadora.

O Futuro do Acesso por Corda:

À medida que continuamos a abraçar as inovações, o futuro do acesso por corda parece promissor. Combinando a eficiência de operações aprimoradas com um compromisso contínuo com a segurança e a sustentabilidade, a SEPIMA está moldando o futuro do acesso por corda na indústria.

Em resumo, a SEPIMA está na vanguarda das inovações em acesso por corda, liderando não apenas em termos de eficiência operacional, mas também estabelecendo um novo padrão para segurança, responsabilidade ambiental e excelência na indústria. Nossa busca constante por melhorias reflete nosso compromisso em oferecer soluções que elevam operações a novas alturas.
                                            
                                            </p>
                                          
                                        </div>
                                    </div>
                                    
                         
                             

                                   
                                </div>
                            </div>

                            {/* Start Sidebar */}
                            <div className="col-xl-5 col-lg-5">
                                <div className="sidebar">
                                    <BlogSearch />
                                    {/* <BlogCategory /> */}
                                    <BlogLatestPost />
                                    <BlogQuery />
                                    {/* <BlogTags /> */}
                                </div>
                            </div>
                            {/* End Sidebar */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}